import { api } from '../axios'

const ZendeskCredentialsService = (() => {
  const fetchAllCredentials = async () => {
    const res = await api.get(`/zendesk-credential`)
    return res.data
  }
  const createCredential = async data => {
    const res = await api.post(`/zendesk-credential`, data)
    return res.data
  }
  const restoreCredential = async id => {
    const res = await api.patch(`/zendesk-credential/${id}/restore`)
    return res.data
  }
  const deleteCredential = async id => {
    const res = await api.delete(`/zendesk-credential/${id}`)
    return res.data
  }

  return {
    fetchAllCredentials,
    createCredential,
    restoreCredential,
    deleteCredential
  }
})()

export default ZendeskCredentialsService
