<template>
  <div>
    <div class="flex justify-between items-center">
      <h2 class="m-0">
        {{ $t('developer-tab.zendesk-credentials.create-form.title') }}
      </h2>
    </div>
    <div class="mt-8">
      <form @submit.prevent="createCredential">
        <div class="d-flex flex-column gap-2 mb-3">
          <div
            class="d-flex align-items-start gap-2"
            v-for="(domain, idx) in form.domains"
            :key="`domain-${idx}`"
          >
            <Field
              type="text"
              class="flex-fill"
              v-model="form.domains[idx]"
              :error="errors[`domains[${idx}]`]"
            />
            <button
              type="button"
              class="px-3 py-2 btn btn-danger"
              @click="removeDomain(idx)"
              :disabled="form.domains.length === 1"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
          <button
            type="button"
            class="bg-white px-3 border w-100 btn btn-sm"
            @click="addDomain"
          >
            {{
              $t(
                'developer-tab.zendesk-credentials.create-form.form.add-domain'
              )
            }}
          </button>
        </div>
        <button class="w-100 btn btn-primary btn-sm" type="submit">
          {{ $t('developer-tab.zendesk-credentials.create-form.form.create') }}
        </button>
      </form>
    </div>

    <modal
      :isOpen="apiKeyModalOpen"
      @dismiss="
        apiKeyModalOpen = false
        $router.push({ name: 'zendesk-credentials' })
      "
      v-if="createdCredential"
    >
      <div class="head">
        <h2 class="mt-0 mb-2">
          {{
            $t(
              'developer-tab.zendesk-credentials.create-form.success-modal.title'
            )
          }}
        </h2>
        <p class="mt-0 mb-2">
          {{
            $t(
              'developer-tab.zendesk-credentials.create-form.success-modal.description'
            )
          }}
        </p>
      </div>
      <div class="w-100 body">
        <div
          class="d-flex justify-content-center align-items-center gap-2 p-2 border rounded"
          role="button"
          @click="copyApiKey()"
        >
          <span class="api-key">
            {{ createdCredential.apiKey }}
          </span>
          <i class="fa fa-clipboard" aria-hidden="true"></i>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { array, object, string } from 'yup'
import ZendeskCredentialsService from '../../../../services/zendeskCredentials.service'
import { yupToKV } from '../../../../utils/yup'
import Field from '../../../../elements/Field.vue'
import Modal from '../../../../elements/Modal'

export default {
  components: {
    Field,
    Modal
  },
  data() {
    return {
      form: {
        domains: ['']
      },
      errors: {},
      createdCredential: null,
      apiKeyModalOpen: false
    }
  },
  computed: {
    createSchema() {
      return object().shape({
        domains: array().of(
          string().required(
            this.$t(
              'developer-tab.zendesk-credentials.create-form.form.domain.required'
            )
          )
        )
      })
    }
  },
  methods: {
    addDomain() {
      this.form = {
        ...this.form,
        domains: [...this.form.domains, '']
      }
    },
    removeDomain(index) {
      this.form = {
        ...this.form,
        domains: this.form.domains.filter((_, idx) => idx !== index)
      }
      this.errors[`domains[${index}]`] = undefined
    },
    async createCredential() {
      try {
        this.errors = {}
        await this.createSchema
          .validate(this.form, { abortEarly: false })
          .catch(err => {
            this.errors = yupToKV(err)
            console.log(this.errors, this.form)
          })

        if (this.createSchema.isValidSync(this.form)) {
          const credential = await ZendeskCredentialsService.createCredential(
            this.form
          )
          this.createdCredential = credential
          this.apiKeyModalOpen = true
          this.toast(
            this.$t(
              'developer-tab.zendesk-credentials.create-form.toasts.success'
            )
          )
        }
      } catch (error) {
        console.log(error)
        this.toast(error.response.data.message, { type: 'error' })
      }
    },
    copyApiKey() {
      try {
        navigator.clipboard.writeText(this.createdCredential.apiKey)
        this.toast('API Key copied to clipboard')
      } catch (error) {
        console.error('Failed to copy: ', error)
        this.toast('Failed to copy API Key', {
          type: 'error'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.gap-2 {
  gap: 0.5rem;
}
.api-key {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
